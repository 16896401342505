.App {
  width: 100%;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.entry-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 4.5vmin;
	max-width: 1110px;
	color: #2c2f34;
  flex-shrink: initial;
}

.entry p {
	font-family: Poppins;
	margin-bottom: 25px;
	line-height: 26px;
  text-align: left;
}

.entry h1 {
  text-align: left;
  font-family: Poppins-SemiBold;
}

.logo {
  margin-top: 10px;
  text-align: right;
}

.linkToPersian {
	text-align: right;
	direction: rtl;
	font-size: 20px;
}

.persian {
	direction: rtl;
	line-height: 2.2em;
	text-align: justify;
}